<template>
  <div>
    <b-form-select
      v-model="item.station"
      :options="options"
      :state="state"
    />
  </div>
</template>

<script>
export default {
  name: 'StationSelect',
  props: {
    item: {
      type: Object,
      required: true
    },
    state: {
      type: Boolean,
      required: false
    }
  },
  data () {
    const options = [
      '- Digital -',
      'LaMusica',
      '- Network -',
      'Aire',
      '- TV -',
      'WSBS Mega TV CH 22 Miami',
      'MegaPR-TV CH 18',
      'MEGA-NTL TV National',
      'KTBU – TV Houston, TX',
      '- Miami -',
      'El Zol 106.7 (WXDJ-FM)',
      'Zeta 92.3 (WCMQ-FM)',
      'Ritmo 95.7 (WRMA-FM)',
      '- New York -',
      'Mega 97.9 (WSKQ-FM)',
      'Amor 93.1 (WPAT-FM)',
      '- Chicago -',
      'La Ley 107.9 FM (WLEY-FM)',
      '- Los Angeles -',
      'Mega 96.3 (KXOL-FM)',
      'La Raza 97.9 (KLAX-FM)',
      '- San Francisco -',
      'La Raza 93.3 (KRZZ-FM)',
      '- Puerto Rico -',
      'Mega 106.9 (WMEG/WEGM-FM)',
      'La Nueva 94 (WODA/WNOD-FM)',
      'Zeta 93 (WZNT/WZET-FM)',
      'Play 96.5 (WIOB/WRXD-FM)',
      '- Tampa -',
      'El Zol 97.1 (WSUN-FM)',
      '- Orlando -',
      'El Zol 95.3 (WPYO-FM)',
      '- Houston -',
      'La Ley 92.1 (KROI-FM)'
    ]

    return {
      options: options.map((option) => {
        if (option.charAt(0) === '-' && option.charAt(option.length - 1) === '-') {
          return {
            value: null,
            text: option,
            disabled: true
          }
        } else {
          return {
            value: option,
            text: option
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
